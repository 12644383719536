body {
  margin: 0;
}

.spec-select {
  z-index: 2;
  box-shadow: none;
  background: #fff;
  border: 1px solid;
  border-radius: .25rem;
  padding: 8px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  position: fixed;
  top: 8px;
  right: 8px;
}

.menu-content > div:first-child > img {
  width: 80px;
  height: auto;
  margin: 20px auto;
  display: block;
}
/*# sourceMappingURL=app.01d13626.css.map */
